import { LOCAL_STORAGE_KEYS } from "../config"

const DEFAULT_LANGUAGE = 'en'

const AVAILABLE_LANGUAGES = {
    'IT': 'it',
    'UK': 'en',
    'ES': 'es',
    'FR': 'fr',
    'PL': 'pl',
    'RO': 'ro',
}

export const setupLanguage = async (pageUrls) => {
    // Get user languange from IP address
    let userOriginLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE)

    if (!userOriginLanguage) {
        const countryCode = await getCountryCode()
        const language = AVAILABLE_LANGUAGES[countryCode] || DEFAULT_LANGUAGE

        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE, language)
        localStorage.setItem(LOCAL_STORAGE_KEYS.GEOLOCATION_COUNTRY, countryCode)

        userOriginLanguage = language
    }

    if (!pageUrls) {
        return
    }

    const urlLanguageMatch = window.location.pathname.split('/')[1];

    if (urlLanguageMatch !== userOriginLanguage) {
        const page = pageUrls.find(url => url.locale === userOriginLanguage)
        const pageUrl = page?.href

        if (pageUrl) {
            const newUrl = `/${userOriginLanguage}${pageUrl}`
            window.location.href = newUrl
        }
    }
}

export const getCountryCode = async () => {
    try {
        const response = await fetch(process.env.NEXT_PUBLIC_IP_API_URL)
        const data = await response.json()

        return data.countryCode
    } catch (error) {
        console.error('Error detecting country: ', error)
    }

    return DEFAULT_LANGUAGE
}

export const getCurrentCountryFrom = (options) => {
    const geolocatedCountry = localStorage.getItem(LOCAL_STORAGE_KEYS.GEOLOCATION_COUNTRY)

    if (!geolocatedCountry) {
        return null
    }

    const response = options.find(option => {
        return option.value.split('|')[0] == geolocatedCountry.toUpperCase()
    })

    return response?.value ?? null
}