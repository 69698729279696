import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { GeneralContext } from 'contexts/General'
import DropDown from '@/UI/Dropdown'
import Text from '@/UI/Text'
import {
  StyledLanguages,
  StyledLanguagesLink,
} from './styles'
import { LOCAL_STORAGE_KEYS } from 'config'


const Languages = ({
  theme,
  ...props
}) => {
  const { locale } = useRouter()
  const {
    page, locales
  } = useContext(GeneralContext)

  return (
    page &&
    page.i18nUrls &&
    <StyledLanguages {...props}>
      <DropDown
        trigger={locale}
        theme={theme}
        triggerProps={{
          translate: 'no',
          css: { textTransform: 'uppercase' }
        }}
      >
        {
          page.i18nUrls.sort((a, b) => {
            const aFullLocale = locales.find(loc => loc.shortenedCode === a.locale)
            const bFullLocale = locales.find(loc => loc.shortenedCode === b.locale)
            return aFullLocale.order < bFullLocale.order ? -1 : 1
          }).map(link => {
            const currentLang = link.locale === locale
            const localeName = link.locale

            return (
              <li key={link.locale}>
                <StyledLanguagesLink
                  translate="no"
                  currentLang={currentLang}
                  href={link.href}
                  locale={link.locale}
                  onClick={() => {
                    // Set the language in localStorage
                    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE, link.locale)
                  }}>
                  <Text size="p3-mid">
                    {localeName.toLocaleUpperCase()}
                  </Text>
                </StyledLanguagesLink>
              </li>
            )
          })
        }
      </DropDown>
    </StyledLanguages>
  )
}

export default Languages
