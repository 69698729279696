import React, {
  useContext,
  useEffect
} from 'react'
import { object } from 'yup'
import { GeneralContext } from 'contexts/General'
import { createValidationSchema } from 'services/models/Form'
import CustomForm from '@/UI/CustomForm'
import { getCurrentCountryFrom } from 'utils/geolocation'

const Form = ({
  form,
  ...rest
}) => {
  const { page } = useContext(GeneralContext)

  const fields = form?.fields ?? []

  useEffect(() => {
    if (!form) {
      return
    }

    const countryForm = fields.fieldsets.find(fieldset => {
      return fieldset.fields.find(field => field.name === 'country')
    })

    if (countryForm) {
      const countryField = countryForm.fields.find(field => field.withGeolocation)

      if (countryField) {
        const countryOptions = countryField.options

        fields.initialValues.country = getCurrentCountryFrom(countryOptions)
      }
    }
  }, [form, fields])

  if (!form) return <></>

  const {
    title,
    endpoint,
    successMessage,
  } = form

  let validationSchema = {}

  fields.originalFieldset.forEach(({ fields: f }) => {
    const fieldsetValidationSchema = f.reduce(createValidationSchema, {})
    validationSchema = {
      ...validationSchema,
      ...fieldsetValidationSchema
    }
  })

  return (
    <CustomForm
      {...rest}
      {...fields}
      title={title}
      successMessage={successMessage}
      endpoint={endpoint}
      validateOnMount
      validationSchema={object().shape(validationSchema)}
      darkMode={page.pageDarkTheme}
      settings={fields.settings}
    />
  )
}


export default Form
