export const GTM = { userProject: 'GTM Transmec Website' }

export const ENTITIES = ['pages', 'scenarios', 'services', 'trades', 'cases', 'jobs', 'news']

export const ENDPOINTS = {
  pages: '/pages',
  scenarios: '/scenarios',
  services: '/services',
  forms: '/forms',
  footer: '/footer',
  options: '/option',
  navigation: '/navigation/render/navigation',
  search: '/search',
  redirects: '/redirects/all',
  trades: '/trades',
  cases: '/cases',
  jobs: '/job-positions',
  news: '/news-items',
  stores: '/store-locator',
  zipCodes: '/zip-codes'
}

export const LOCAL_STORAGE_KEYS = {
  USER_LANGUAGE: 'USER_LANGUAGE',
  GEOLOCATION_COUNTRY: 'GEOLOCATION_COUNTRY'
}