import React, { createContext } from 'react'
import locales from '../locales.json'

export const GeneralContext = createContext({
  options: null, additionalData: null
})

const GeneralProvider = ({
  options,
  page,
  scenarios,
  services,
  geolocatedCountry,
  children
}) => (
  <GeneralContext.Provider
    value={{
      options,
      page,
      scenarios,
      services,
      locales,
      geolocatedCountry
    }}
  >
    {children}
  </GeneralContext.Provider>
)

export default GeneralProvider
