import { useContext, useEffect } from "react";
import { setupLanguage } from "utils/geolocation";
import { GeneralContext } from "./General";

const LanguageProvider = ({ children }) => {
    const { page } = useContext(GeneralContext)

    const pageUrls = page?.i18nUrls

    useEffect(() => {
        if (pageUrls) {
            setupLanguage(pageUrls)
        }
    }, [pageUrls])

    return <>{children}</>;
};

export default LanguageProvider;
