import React from 'react'
import { useField } from 'formik'
import CustomField from './index'

const Select = ({
  options = [],
  label,
  dependsOn,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)

  return (
    <CustomField
      label={label}
      field={field}
      meta={meta}
      helpers={helpers}
      dependsOn={dependsOn}
    >
      <select {...field} {...props}>
        {
          options.map(({
            value,
            label: optionLabel
          }, idx) => {
            return <option key={idx} value={value} label={optionLabel}>{optionLabel}</option>
          })
        }
      </select>
    </CustomField>
  )
}

export default Select
